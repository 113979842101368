const Constants = require('./Constants');

export const validEmailRegEx =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export const MAX_EMAIL_LENGTH = Constants.MAX_STRING_LENGTH_255;

export function isValidEmail(email: string) {
  return (
    validEmailRegEx.test(email) &&
    email.length <= Constants.MAX_STRING_LENGTH_255
  );
}
